








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Filiale } from "@/models/filiale";
import { IsMobile } from "@/utils/Mobile";
import { BookingModule } from "@/features/booking/store";

@Component({})
export default class FilialeSelector extends Vue {
  @Prop()
  public provincia: string | undefined = undefined;
  private isDemo = process.env.VUE_APP_DEMO === "true";

  mounted(): void {
    window.addEventListener("resize", () => {
      const c = document.getElementById("content") as HTMLElement;
      c.style.height = `${window.innerHeight - 400}px`;
    });
  }

  search = "";
  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "nome",
    },
    {
      text: "Indirizzo",
      align: "start",
      sortable: true,
      value: "indirizzo",
    },
    {
      text: "Località",
      align: "start",
      sortable: true,
      value: "citta",
    },
    {
      text: "CAP",
      align: "start",
      sortable: true,
      value: "cap",
    },
  ];

  get filialiInProvincia(): Array<Filiale> {
    let collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });

    return BookingModule.Filiali.filter(
      (f: Filiale) =>
        f.Provincia == this.provincia &&
        f.Citta.toLowerCase().includes(this.search.toLowerCase())
    ).sort(
      (a: Filiale, b: Filiale) =>
        a.Citta.localeCompare(b.Citta) || collator.compare(a.Nome, b.Nome)
    );
  }

  private select(i: number, filiale: Filiale): void {
    const event = "select";
    this.$emit(event, { filiale: filiale, pos: i + 1 });
  }

  get isMobile(): boolean {
    return IsMobile();
  }

  private IndirizzoShort(f: Filiale): string {
    if (!this.isMobile) {
      return f.Indirizzo;
    }

    return `${f.Indirizzo} (${f.Citta})`;
  }
}
