import { Filiale } from "@/models/filiale";
import axios from "axios";

export async function GetFiliali(): Promise<Array<Filiale>> {
  const dataURL = process.env.VUE_APP_FILIALI_URL;
  const r = await axios.get<Array<Filiale>>(dataURL, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "x-ms-*,content-*",
      "content-type": "application/json",
    },
  });
  return r.data;
}
