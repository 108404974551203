<template>
  <v-app>
    <nav-bar />

    <v-main>
      <v-container>
        <router-view>
          <home-view />
        </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import HomeView from "@/views/HomeView";
import NavBar from "@/components/NavBar";

@Component({
  components: { HomeView, NavBar },
})
export default class App extends Vue {}
</script>
