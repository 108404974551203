import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { Filiale } from "@/models/filiale";
import { Undefinable } from "@/utils/Undefineable";

export interface FilialeState {
  Provincia: string | undefined;
  Filiale: { filiale: Filiale; pos: number } | undefined;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: "booking",
  store: store,
})
class BookingStore extends VuexModule implements FilialeState {
  private provincia: string | undefined = undefined;
  private filiale: Undefinable<{ filiale: Filiale; pos: number }> = {
    Instance: undefined,
  };
  private filiali = new Array<Filiale>();

  @Mutation
  public SET_FILIALE(f?: { filiale: Filiale; pos: number }): void {
    this.filiale.Instance = f;
  }

  get Filiale(): { filiale: Filiale; pos: number } | undefined {
    return this.filiale?.Instance;
  }

  @Mutation
  public SET_PROVINCIA(p?: string): void {
    this.provincia = p;
  }

  get Provincia(): string | undefined {
    return this.provincia;
  }

  @Mutation
  public SET_FILIALI(ff: Array<Filiale>): void {
    this.filiali = ff;
  }

  get Filiali(): Array<Filiale> {
    return this.filiali;
  }
}

export const BookingModule = getModule(BookingStore, store);
