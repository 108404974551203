











import router from "@/router";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NavBar extends Vue {
  toHome(): void {
    router.push({ name: "home" });
  }
}
