


































import { Component, Vue } from "vue-property-decorator";
import { BookingModule } from "@/features/booking/store";

@Component({})
export default class ProvinciaSelector extends Vue {
  provincia = "";

  select(provincia: string): void {
    const event = "select";
    this.$emit(event, provincia);
  }

  get province(): Array<{ long: string }> {
    return BookingModule.Filiali.map((f) => f.Provincia)
      .filter((v, i, s) => s.indexOf(v) === i)
      .map((f) => {
        return { long: f };
      })
      .sort();
  }
}
