

















































import { Component, Vue } from "vue-property-decorator";
import FilialeSelector from "@/components/FilialeSelector.vue";
import ProvinciaSelector from "@/components/ProvinciaSelector.vue";
import { Filiale } from "@/models/filiale";
import { BookingModule } from "./store";
import MSBookingsFrame from "@/components/MSBookingsFrame.vue";
import { GetFiliali } from "@/services/FilialiProvider";

@Component({
  components: { ProvinciaSelector, FilialeSelector, MSBookingsFrame },
})
export default class Booking extends Vue {
  step = 1;

  async created(): Promise<void> {
    const ff = await GetFiliali();
    BookingModule.SET_FILIALI(ff);
  }

  get provincia(): string {
    const p = BookingModule.Provincia;
    return p === undefined ? "" : p;
  }

  set provincia(provincia: string) {
    BookingModule.SET_PROVINCIA(provincia);
  }

  setProvincia(provincia: string) {
    this.filiale = undefined;
    this.provincia = provincia;
    this.step = 2;
  }

  get filiale(): { filiale: Filiale; pos: number } | undefined {
    return BookingModule.Filiale;
  }

  set filiale(event: { filiale: Filiale; pos: number } | undefined) {
    if (process.env.VUE_APP_DEMO === "true" && event !== undefined) {
      event.filiale.PublicUrl =
        event.pos % 2 == 0
          ? "https://outlook.office365.com/owa/calendar/FilialediRagusa2@progesoftware.it/bookings/"
          : "https://outlook.office365.com/owa/calendar/ProgeSoftwaresrl52@progesoftware.it/bookings/";
      event.filiale.BookingsId =
        event.pos % 2 == 0
          ? "FilialediRagusa2@progesoftware.it"
          : "ProgeSoftwaresrl52@progesoftware.it";
    }
    BookingModule.SET_FILIALE(event);
  }

  setFiliale(event: { filiale: Filiale; pos: number }) {
    this.filiale = event;
    this.step = 3;
  }

  get isFilialeSet(): boolean {
    return BookingModule.Filiale !== undefined;
  }
}
