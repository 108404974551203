






import { Component, Vue } from "vue-property-decorator";
import Booking from "@/features/booking/Booking.vue";

@Component({
  components: { Booking },
})
export default class HomeView extends Vue {}
