
























import { Component, Vue } from "vue-property-decorator";
import { BookingModule } from "@/features/booking/store";

@Component({})
export default class MSBookingsFrame extends Vue {
  get frameHRef(): string {
    return BookingModule.Filiale?.filiale.PublicUrl ?? "";
  }

  private loaded = false;

  mounted() {
    const ef = document.getElementById("iframe") as HTMLObjectElement;
    ef.onload = () => (this.loaded = true);
  }
}
